<template>
  <div>
    <div class="title">领导成员</div>
    <leaderCard />
  </div>
</template>

<script>
import leaderCard from "~jzs/components/survey/leader/leaderCard";
export default {
  metaInfo() {
    return {
      title: this.$t("associationTips5"),
      meta: [
        {
          name: "keyWords",
          content: "上海市国际货运代理行业领导成员,上海货代领导成员",
        },
        {
          name: "description",
          content:
            "上 海 市 国 际 货 运 代 理 行 业 协 会 （ shanghai international freight forwarders association）是在我国改革开放不断深化，国际货运代 理业快速发展条件下，于 1992 年 9 月成立，是我国（除港澳台地区外）最早成立的省 市级国际货运代理行业协会。",
        },
      ],
    };
  },
  components: { leaderCard },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>